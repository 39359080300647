<template>
  <b-card class="card-custom rounded-top-0">
    <b-row>
      <b-col lg="4" class="mb-7 mb-lg-0">
        <b-card body-class="d-flex p-0" class="card-custom card-stretch min-h-425px">
          <div class="flex-grow-1 p-10 card-rounded bgi-no-repeat d-flex flex-column align-items-start">
            <!--  :style="{
              backgroundPosition: 'right bottom',
              backgroundSize: 'auto 350px',
              backgroundImage: 'url(media/svg/humans/custom-10.svg)',
            }" -->
            <img src="/media/svg/humans/custom-10.svg" width="100%" class="inst_imgBanner" alt="" />
            <!-- <h4 class="font-weight-bolder m-0">What are Instructions for?</h4>
            <p class="text-dark-50 my-5 font-size-xl font-weight-bold">
              Lorem ipsum do lor sit amet,consectetuer edipiscing elit,
              <br />
              sed diam nonummy euismod tinciduntut laoreet doloremagna
            </p> -->
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <div class="py-4">
          <h5 class="text-primary">Who schedules the interview</h5>
          <p>Board President</p>

          <div class="separator separator-solid my-8" />

          <h5 class="text-primary">Additional Instructions</h5>
          <p v-html="detailsData.additional_instruction" />

          <div class="separator separator-solid my-8" />

          <h5 class="text-primary">Remarks</h5>
          <p class="mb-0" v-html="detailsData.remarks" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'cooperatives-view-instructions',
  props: ['detailsData'],
  mounted() {
    this.$root.$emit('changeTitle', this.detailsData.company_name);
  },
};
</script>
<style scoped>
.inst_imgBanner {
  /*position: sticky;
    top: 100px; */
}
</style>
